import axios from 'axios'
import { AxiosRequestConfig } from 'axios'
import { useRouter } from 'vue-router'

const config = {
  baseURL: import.meta.env.VITE_BASE_API,
  timeout: 30000, // 30s
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
}

export default function () {
  // const route = useRoute()
  const router = useRouter()

  const REQUEST = <T>(conf: AxiosRequestConfig) => {
    return new Promise<T>(async (resolve, reject) => {
      try {
        const response = await axios.request({ ...config, ...conf })
        resolve(response.data)
      } catch (error) {
        handleErrors(error, reject)
      }
    })
  }
  const GET = <T>(url: string, params = {}) => {
    return REQUEST<T>({ method: 'get', url, params })
  }
  const POST = <T>(url: string, data: object = {}) => {
    return REQUEST<T>({ method: 'post', url, data })
  }
  // const PUT = <T>(url: string, data: object) => {
  //   return REQUEST<T>({ method: 'put', url, data })
  // }
  // const DELETE = <T>(url: string, data = {}) => {
  //   return REQUEST<T>({ method: 'delete', url, data })
  // }

  const onError = {
    unauthorized: () => {
      //
    },
    maintenance: () => {
      router.replace({ name: 'maintenance' })
    },
    forbidden: () => {
      //
    },
    notFound: () => {
      //
    },
    csrfTokenMismatch: () => {
      //
    },
    validationFailed: () => {
      //
    },
    tooManyRequest: () => {
      // toast.add('Silakan tunggu beberapa menit sebelum mencoba kembali')
    },
    networkError: () => {
      // toast.add('Tidak terhubung dengan internet')
    },
  }

  /* https://github.com/axios/axios#handling-errors */
  const handleErrors = <T>(
    error: unknown,
    reject: (reason: unknown) => void,
  ) => {
    if (!axios.isAxiosError(error)) {
      /**
       * Not axios error
       * Something happened in setting up the request that triggered an Error
       */
      reject(error)
      return
    }

    if (error.response) {
      /**
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      switch (error.response.status) {
      case 401: onError.unauthorized(); break
      case 403: onError.forbidden(); break
      case 404: onError.notFound(); break
      case 419: onError.csrfTokenMismatch(); return
      case 422: onError.validationFailed(); break
      case 429: onError.tooManyRequest(); break
      case 503: onError.maintenance(); break
      default: break
      }
      if (error.message === 'Network Error') {
        onError.networkError()
      }
      reject(error)
      console.error('[Axios error]', error)
      return
    }

    /**
     * The request was made but no response was received.
     * `error.request` is an instance of XMLHttpRequest
     * in the browser and an instance of
     * http.ClientRequest in node.js
     */
    if (error.request) {
      console.error('[API] Axios error but no response', error)
    }
    reject(error)
  }

  return {
    GET,
    POST,
    // PUT,
    // DELETE,
    config,
  }
}